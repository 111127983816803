/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.hidden{
  display: none;
}

.Documentation-View{
  display: grid;
}

.codDoc-Container{
  display: grid;
  justify-self: center;

}


.codDoc-Container>section{
  display: grid;
}
code{
  display: grid;
  padding: 10px;
  width: auto;
  background-color: #2862AF;

  /* color: white; */
  /* justify-items: center; */

}


pre {
  padding: .5rem;
  /* overflow: scroll; */
  border-radius: 3px;
  max-width: 75em;
  border-radius: 7px;
  background-color: rgb(28, 28, 28);
  /* background: linear-gradient(to bottom, #00aecd, #136DC0 20%); */
  background-color: rgba(0, 0, 0, 0.855);
  height: 90%;

}

pre code {
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
}

pre::-webkit-scrollbar{
  display: none;
}



.algorithm-container{
  display: grid;
  grid-template-columns: 20em 1fr;
  row-gap: 20px;
  justify-content: center;
  /* width: 100vw; */
  background-color: whitesmoke;
  
}

.algorithm-container>header nav{
  background-color: #2862AF;
  background: linear-gradient(to top, #00aecd, #136DC0 55%);

  position: sticky;
  top: 0;
  padding: 30px;
  height: 100vh;

}

.algorithm-container>header nav ul{
  display: grid;
  row-gap: 10px;
}

.algorithm-container>header nav h1{
  color: white;
  font: 1.5em sans-serif;
}

.algorithm-container>header nav ul li a{
  color: white;
  font: 1.2em sans-serif;
  text-decoration: none;
}

.algorithm-container>main section ul::-webkit-scrollbar{
  display: none;
  width: 0;
}

.algorithm-container>main section{
  display: grid;
  align-items: flex-start;
  border-top: #136cc028 dashed 2px;

}

@media screen and (min-width: 1100px ) {
  .algorithm-container>main section{
    grid-template-columns: 40em 1fr;
  
  }
  
}



.algorithm-container>main section header{
  padding: 20px;

}


.algorithm-container>main section ul{
  display: flex;
  column-gap: 10px;
  overflow: scroll;
  
}
.algorithm-container>main section ul li{
  display: grid;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.23);
  padding: 40px;
  margin: 10px;
  border-radius: 10px;
  max-width: 45em;
  /* height: 88vh; */
  align-self: flex-start;
  align-content: flex-start;
}

/* .algorithm-container>section ul li pre code{
  filter: blur(5px);
} */

.algorithm-container>section ul h4{
  color:#2862AF ;
  font: 1.3em sans-serif;
  margin: 0;
}